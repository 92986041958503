import { getUserInfo } from './index.js';
import { getUserSubject } from './exam';
/**
 * @description:  获取用户信息
 */
export { getUserInfo, getUserSubject };
/**
 * @description:  获取用户题册列表
 */
export function getUserSTFavoriteType(query) {
  return fetchData('/api/FavoriteApi/GetUserSTFavoriteType', { method: 'get', query });
};

/**
 * @description:  获取用户搜题包记录接口
 * @param query
 */
export function getSearchPackUseRecord(query) {
  return fetchData('/api/SearchPackApi/GetSearchPackUseRecord', { method: 'get', query, valid: true });
};

/**
 * @description:  获取查看答案记录接口
 */
export function getUserQueryAnswerRecord(query) {
  return fetchData('/api/UserSTRecordApi/GetUserQueryAnswerRecord', { method: 'get', query, valid: true  });
};

/**
 * @description:  获取用户优惠券列表
 */
export async function getCouponList(query) {
  const data = await fetchData('/api/ShopCouponUserApi/GetShopCouponUserList', { method: 'get', query });
  if (data.S === "1") {
    data.ShopCouponUserList = data.ShopCouponUserList.filter(
      (coupon) => coupon.UseFlag === "0" && coupon.IsExpiration === "0"
    );
    if (data?.shopCouponUserList?.length > 0) {
      data.shopCouponUserList = sortByKey(data.shopCouponUserList);
    }
  }
  return Promise.resolve(data);
};

/**
 * @description:  新版获取首页信息接口(现用于获取相关题库相关内容)
 */
export function getRelativeCourse(query) {
  return fetchData('/api/ArticleApi/GetNewHomePage', { method: 'get', query });
};

/**
 * @description:  获取用户已购买的网校课程接口
 */
export function getSchoolBuyByUserID(query) {
  return fetchData('/api/BuyCourseApi/GetSchoolBuyByUserID', { method: 'get', query });
};



/**
 * 获取消费记录
 */
export const getUserPurchase = (query) => {
  return fetchData('/api/UserApi/GetUserPurchaseRecord', { method: 'get', query, valid: true });
};

/**
 * 获取订单详情
 */
export const getOrderByOrderID = (query) => {
  return fetchData('/api/OrderApi/GetOrderByOrderID', { method: 'get', query });
};

/**
 * 获取验证码
 */
export const sendSmsCode = (query) => {
  return fetchData('/api/SendSmsApi/SendSmsCode', { method: 'get', query });
};

/**
 * 绑定手机
 */
export const bindPhone = (query) => {
  return fetchData('/api/UserApi/BindPhone', { method: 'get', query });
};

/**
 * 修改密码
 */
export const updatePwd = (query) => {
  return fetchData('/api/UserApi/GetBackPassword', { method: 'get', query, valid: true });
};

/**
 * 注销账号
 */
export const logOffAccount = (query) => {
  return fetchData('/api/UserApi/LogOffAccount', { method: 'get', query });
};

/**
 * 获取微信登录二维码接口
 */
export const wxQRCodeLogin = (query) => {
  return fetchData('/api/UserLoginRegApi/WXQRCodeLogin', { method: 'get', query, valid: true });
};
/**
 * 账号密码登录
 */
export const accountLogin = (query) => {
  return fetchData('/api/UserLoginRegApi/UserLogin', { method: 'get', query, valid: true });
};

/**
 * 获取注销协议
 */
export const getNewHelp = (query) => {
  return fetchData('/api/HelpApi/GetNewHelp', { method: 'get', query, valid: true });
};

/**
 * 账号注册
 */
export const accountReg = (query) => {
  return fetchData('/api/UserLoginRegApi/UserReg', { method: 'get', query, valid: true });
};

